import React from 'react';
import Layout from '../components/layout/Layout';
import Error from '../svg/Error';
import SEO from '../components/layout/seo';

const NotFound = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className='flex flex-col items-center align-center justify-center'>
    <Error/>
    </div>
 
    </Layout>
);

export default NotFound;
