import * as React from "react"

const Error = (props) => (
<svg
    xmlSpace="preserve"
    width={666.667}
    height={666.667}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"404 page not found"}</title>
    <defs>
      <clipPath clipPathUnits="userSpaceOnUse" id="b">
        <path d="M0 500h500V0H0Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="c">
        <path d="M186.11 318.334h279.847V106.445H186.11Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="d">
        <path d="M167.804 256.129h27.813v-60.395h-27.813z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="e">
        <path d="M197.202 177.253h146.863v-7.248H197.202Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="f">
        <path d="M201.295 164.334h140.239v-14.936H201.295Z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="g">
        <path d="M341.534 164.334H201.295v-14.936h140.239z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="j">
        <path d="M352.934 117.162h46.776v-7.435h-46.776z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="k">
        <path d="M373.015 162.074h24.85v-47.899h-24.85z" />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="l">
        <path d="M0 500h500V0H0Z" />
      </clipPath>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 24.05441 24.05441 0 271.414 138.657)"
        spreadMethod="pad"
        id="a"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#fff",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 0,
            stopColor: "#fff",
          }}
          offset={0.649}
        />
        <stop
          style={{
            stopOpacity: 0,
            stopColor: "#fff",
          }}
          offset={1}
        />
      </linearGradient>
      <linearGradient
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 24.05441 24.05441 0 271.414 138.657)"
        spreadMethod="pad"
        id="i"
      >
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#fff",
          }}
          offset={0}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#fff",
          }}
          offset={0.649}
        />
        <stop
          style={{
            stopOpacity: 1,
            stopColor: "#fff",
          }}
          offset={1}
        />
      </linearGradient>
      <mask maskUnits="userSpaceOnUse" x={0} y={0} width={1} height={1} id="h">
        <path
          d="M-32768 32767h65535v-65535h-65535Z"
          style={{
            fill: "url(#a)",
            stroke: "none",
          }}
        />
      </mask>
    </defs>
    <path
      d="M0 0h500v500H0Z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(1.33333 0 0 -1.33333 0 666.667)"
    />
    <g clipPath="url(#b)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
      <path
        d="M0 0c6.95 0 12.624 5.573 12.73 12.524.533 34.645 3.324 85.747-61.921 99.879-52.799 11.437-21.495 105.268-163.689 99.206-102.073-4.352-74.955-90.387-154.32-97.552-52.81-4.768-53.266-71.559-51.237-102.162C-417.993 5.2-412.44 0-405.731 0Z"
        style={{
          fill: "#b5dfea",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(453.059 106.444)"
      />
      <g clipPath="url(#c)" opacity={0.55}>
        <path
          d="M0 0c-52.799 11.437-21.494 105.268-163.688 99.206-24.277-1.036-41.237-6.697-54.069-14.813 4.322.565 8.911.965 13.8 1.173C-61.762 91.629-93.066-2.202-40.268-13.639c64.55-13.982 62.506-64.147 61.941-98.764h27.518c6.951 0 12.624 5.573 12.731 12.524C62.454-65.234 65.245-14.133 0 0"
          style={{
            fill: "#90cedd",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(403.867 218.848)"
        />
      </g>
      <path
        d="M177.771 336.201h2.245V500h-2.245zM338.744 311.039h2.245V500h-2.245z"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="m0 0-166.947 19.486c-7.135.833-13.594-4.276-14.427-11.41l-12.46-106.755c-.833-7.134 4.276-13.593 11.411-14.426l166.946-19.487c7.135-.832 13.594 4.277 14.426 11.411L11.41-14.426C12.243-7.292 7.134-.833 0 0"
        style={{
          fill: "#d7f0f9",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(340.774 322.054)"
      />
      <path
        d="m0 0 1.233 10.567c.833 7.134-4.276 13.594-11.41 14.426l-166.947 19.486c-7.135.833-13.593-4.276-14.426-11.41l-1.234-10.567z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(350.951 297.06)"
      />
      <path
        d="m0 0 2.73 23.39c.019.162-.199.233-.279.091L-10.053 1.418a.627.627 0 0 1 .472-.93L-.694-.549A.626.626 0 0 1 0 0m-19.407 2.789L1.756 40.075a6.836 6.836 0 0 0 12.735-4.167L10.089-1.808l.188-.022a4.736 4.736 0 1 0-1.098-9.41l-.188.023-.802-6.869A5.116 5.116 0 0 0-1.973-16.9l.802 6.869-15.235 1.778a4.745 4.745 0 0 0-4.163 5.263l.404 3.461c.095.816.353 1.604.758 2.318"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(204.584 252.132)"
      />
      <path
        d="m0 0 2.729 23.39c.02.162-.198.234-.279.091L-10.053 1.419a.627.627 0 0 1 .472-.931L-.694-.549A.627.627 0 0 1 0 0m-19.407 2.789L1.755 40.076a6.837 6.837 0 0 0 12.736-4.167L10.089-1.808l.188-.021a4.738 4.738 0 0 0-1.098-9.41l-.189.022-.802-6.869a5.113 5.113 0 0 0-5.673-4.487 5.114 5.114 0 0 0-4.488 5.674l.802 6.869-15.235 1.777a4.745 4.745 0 0 0-4.163 5.264l.404 3.461c.095.815.353 1.603.758 2.317"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(297.981 241.23)"
      />
      <path
        d="M0 0c-.549-4.705 1.312-6.733 4.605-7.118 3.294-.385 5.572 1.161 6.122 5.866l4.151 35.567c.549 4.705-1.312 6.734-4.605 7.119-3.294.384-5.572-1.162-6.122-5.867zm-6.275 36.117c1.23 10.538 7.483 15.912 17.646 14.726 10.162-1.186 15.01-7.856 13.781-18.395L21.154-1.801C19.923-12.34 13.669-17.714 3.507-16.528c-10.162 1.186-15.01 7.856-13.78 18.395z"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(242.864 240.74)"
      />
      <path
        d="M0 0a3.496 3.496 0 1 0-6.944.81A3.496 3.496 0 0 0 0 0"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(177.848 326.788)"
      />
      <path
        d="M0 0a3.496 3.496 0 1 0-6.945.811A3.496 3.496 0 0 0 0 0"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(194.594 324.833)"
      />
      <path
        d="M0 0a3.495 3.495 0 1 0-6.943.81A3.495 3.495 0 0 0 0 0"
        style={{
          fill: "#ffbf4d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(211.953 322.807)"
      />
      <path
        d="M0 0s-2.255-9.997-1.879-20.221c.336-9.151-.752-69.309-.752-69.309h7.348s10.46 47.116 10.851 50.837c.39 3.72 5.652 40.929 5.652 40.929z"
        style={{
          fill: "#00668e",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(144.793 201.208)"
      />
      <path
        d="M0 0s1.691-12.854 4.567-21.987c2.875-9.133 17.007-70.287 17.007-70.287h7.348s-1.353 47.116-1.86 50.836c-.508 3.721-4.229 40.93-4.229 40.93z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(149.68 203.952)"
      />
      <path
        d="M0 0v-5.128h13.988c.794 0 1.105 1.029.443 1.47C12.386-2.296 9.109-.419 6.063 0Z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(171.853 111.69)"
      />
      <path
        d="M0 0v-5.128h13.988c.794 0 1.105 1.029.444 1.47C12.386-2.296 9.109-.419 6.063 0Z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(142.537 111.69)"
      />
      <path
        d="M0 0s4.811 7.305-.713 8.018c-4.241.547-5.986-.716-5.986-.716s-4.883.538-7.555-1.779c-1.832-1.587-8.553-13.898 2.316-18.352C-1.069-17.283-3.386-5.88-3.386-5.88z"
        style={{
          fill: "#00668e",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(167.774 263.745)"
      />
      <path
        d="M0 0c-4.241.547-5.986-.716-5.986-.716s-4.883.538-7.556-1.779c-1.831-1.587-8.552-13.898 2.317-18.352 5.109-2.094 7.304-.683 8.196 1.399-10.312-2.901-11.43 9.528-9.73 13.065 1.728 3.593 5.875 1.935 5.875 1.935C-5.263-.366.022-.809 2.201-1.126 1.804-.559 1.115-.144 0 0"
        style={{
          fill: "#05556d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(167.061 271.763)"
      />
      <path
        d="M0 0s-1.425 1.425-2.495 0c-1.068-1.426-.356-4.633 1.426-4.811 0 0-.535-4.633-4.098-4.455v-3.741h8.375v2.851s5.167 2.138 4.988 6.414C8.018.534 6.771 3.563 6.771 3.563S3.92.356 0 0"
        style={{
          fill: "#f98d3d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(161.003 260.182)"
      />
      <path
        d="M0 0c.829-.345.684-.868.721-1.652h1.205v2.85S-.104.553-3.076 2.247c0 0 1.73-1.686 3.076-2.247"
        style={{
          fill: "#ed701b",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(162.285 248.827)"
      />
      <path
        d="M0 0s-4.341 1.729-4.848.432c-.332-.849.512-1.58 1.671-2.319l-1.022-.168c-.227-.037-.309-.327-.13-.472.285-.23 1.072-.385 2.713-.442z"
        style={{
          fill: "#f98d3d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(163.999 267.001)"
      />
      <path
        d="m0 0-1.473 3.218-4.691 10.251 7.784 8.718L.221 3.056Zm50.655 49.669c-8.277 6.134-30.884 11.038-30.884 11.038l-1.862-3.394s20.523-7.954 19.168-9.139c-.796-.699-10.825-5.295-14.22-6.841a5.408 5.408 0 0 0-2.334-.479l-10.391.059s-7.496.892-13.394-5.884c-5.273-6.067-14.235-20.117-14.235-20.117L-2.401-7.29l1.672-2.452.015.029-.015-.206s.537-.059 1.487-.147c4.853-.45 20.406-1.679 28.638.066l.229.052-.391 36.421c13.144 10.405 19.418 18.439 21.664 20.965a1.52 1.52 0 0 1-.243 2.231"
        style={{
          fill: "#ffbf4d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(144.34 206.46)"
      />
      <path
        d="m0 0 1.473-3.218-.714-9.713-.015-.029-1.672 2.452z"
        style={{
          fill: "#efaa3a",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(142.867 209.678)"
      />
      <g clipPath="url(#d)" opacity={0.29}>
        <path
          d="M0 0c-1.398-1.016-1.044-10.023-1.39-15.655-.578-9.431-1.141-17.592-.725-22.854 2.13.141 4.113.371 5.788.727l.229.052-.391 36.42C16.656 9.096 22.93 17.129 25.176 19.655c.589.67.478 1.694-.243 2.232C24.933 21.887 14.542 10.56 0 0"
          style={{
            fill: "#f98d2b",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(170.063 234.242)"
        />
      </g>
      <path
        d="M0 0c-.366 2.685-2.405 22.804-3.513 33.801l-5.28-.582s7.703-63.332 8.21-84.056H1.86S.507-3.721 0 0"
        style={{
          fill: "#00668e",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(176.74 162.515)"
      />
      <path
        d="m0 0-1.592 2.855A10.727 10.727 0 0 1-.338 7.896c0 5.954-4.827 10.781-10.781 10.781-5.955 0-10.781-4.827-10.781-10.781 0-5.955 4.826-10.782 10.781-10.782 2.997 0 5.707 1.225 7.661 3.2z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(142.881 259.143)"
      />
      <path
        d="M0 0h.42v-1.503c0-.46.137-.658.46-.658.253 0 .43.127.663.415V0h.415v-2.459h-.344l-.035.384h-.016c-.227-.268-.47-.445-.809-.445-.522 0-.754.334-.754.961Z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(126.154 268.012)"
      />
      <path
        d="M0 0c.405 0 .704.369.704.977 0 .541-.183.905-.648.905-.208 0-.415-.116-.658-.333V.258C-.379.071-.162 0 0 0m-.602-.496v-.83h-.416v3.497h.345l.035-.283h.015c.223.187.496.343.785.343.632 0 .971-.49.971-1.254C1.133.142.632-.349.071-.349c-.228 0-.455.106-.684.284z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(130.464 265.841)"
      />
      <path
        d="M0 0c.212-.172.43-.294.724-.294.323 0 .485.172.485.385 0 .253-.293.364-.562.465-.349.127-.733.294-.733.709 0 .395.314.698.845.698.309 0 .577-.127.764-.278l-.197-.263c-.167.126-.344.217-.562.217-.308 0-.45-.167-.45-.354 0-.227.268-.319.546-.425.354-.131.749-.278.749-.744 0-.399-.319-.734-.901-.734-.349 0-.683.147-.916.34z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(132.694 266.11)"
      />
      <path
        d="M0 0c.162 0 .298-.131.298-.324A.302.302 0 0 0 0-.637a.304.304 0 0 0-.304.313C-.304-.131-.167 0 0 0"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(135.602 266.13)"
      />
      <path
        d="M0 0c.162 0 .298-.131.298-.324A.302.302 0 0 0 0-.637a.304.304 0 0 0-.304.313C-.304-.131-.167 0 0 0"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(137.368 266.13)"
      />
      <path
        d="M0 0s-2.265 2.115-.745 10.139 5.376 15.73 3.485 24.434C.848 43.279-13.985 65.229-14.035 66.688c-.05 1.459-4.576-25.535 3.095-36.168 7.67-10.634 8.593-14.324 8.253-19.916-.341-5.592.079-10.095.92-11.395z"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(101.545 129.506)"
      />
      <path
        d="M0 0c-.892-3.58-1.43-12.555-.005-14.703.133-.201.324-.087.562.284C.123-12.936-.162-10.335.659-6c1.52 8.024 5.376 15.73 3.485 24.435C2.252 27.141-12.582 49.09-12.631 50.55c0 0 2.235-18.288 9.763-30.875C2.521 10.663.892 3.579 0 0"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(100.141 145.644)"
      />
      <path
        d="M0 0s-4.357 10.88 3.028 20.301c7.385 9.42 19.196 21.515 19.431 26.307 0 0 3.036-22.751-6.577-29.191C6.269 10.978-.473 9.95 1.194.041Z"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(104.25 130.063)"
      />
      <path
        d="M0 0c-10.719-7.802-11.595-16.789-9.932-20.957.13-.324.374-.522.698-.628-1.437 9.564 5.249 10.652 14.74 17.011 9.613 6.44 6.578 29.191 6.578 29.191S10.719 7.802 0 0"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(114.626 152.054)"
      />
      <path
        d="M0 0s1.398 5.761.654 11.98c-.743 6.22-7.241 9.719-13.311 16.289-3.124 3.379-4.048 21.254-2.111 22.915 0 0-10.718-12.989-9.065-22.499C-22.18 19.174-4.695 12.994-3.077 8.398-1.458 3.804-1.592-.055-1.592-.055z"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(97.083 129.818)"
      />
      <path
        d="M0 0c-3.542-9.593.089-21.211 11.856-28.427 8.664-5.313 5.905-16.36 5.905-16.36l1.415 4.358c.125 1.885.139 4.079-.131 6.338-.744 6.22-7.241 9.719-13.312 16.288C2.721-14.543 1.755 2.195 3.428 4.87A57.441 57.441 0 0 1 0 0"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(78.692 175.89)"
      />
      <path
        d="M0 0a25 25 0 0 0 .195.242A1.034 1.034 0 0 1 0 0"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(82.12 180.76)"
      />
      <path
        d="M0 0s2.512 9.32 8.725 10.575c6.213 1.255 13.347 10.436 13.733 12.237C22.458 22.812 21.41 5.497 0 0"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(106.493 130.538)"
      />
      <path
        d="M0 0c-3.807-1.903-5.39-4.464-5.952-6.788 19.062 6.13 20.04 22.105 20.04 22.105-.076-.356-.424-1.006-.969-1.821C11.25 10.221 6.724 3.362 0 0"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(114.863 138.032)"
      />
      <path
        d="M0 0h-32.495l3.859-24.27a3.792 3.792 0 0 1 3.744-3.196h17.289a3.791 3.791 0 0 1 3.744 3.196z"
        style={{
          fill: "#ffbf4d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(119.937 133.97)"
      />
      <path
        d="m0 0 .638 4.015h-32.494L-31.218 0Z"
        style={{
          fill: "#ff9f50",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(119.299 129.955)"
      />
      <path
        d="m0 0-3.859-24.27a3.792 3.792 0 0 0-3.744-3.196H.27a3.791 3.791 0 0 1 3.743 3.196L7.872 0Z"
        style={{
          fill: "#ff9f50",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(112.065 133.97)"
      />
      <path
        d="M344.065 147.393H197.202v29.86h146.863z"
        style={{
          fill: "#ffbf4d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <g clipPath="url(#e)" opacity={0.29}>
        <path
          d="M344.065 170.005H197.202v7.248h146.863z"
          style={{
            fill: "#f98d2b",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
        />
      </g>
      <path
        d="M0 0h13.404L9.812-7.251H-3.6Z"
        style={{
          fill: "#f77e2d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(209.972 177.253)"
      />
      <path
        d="M0 0h13.404L9.812-7.251H-3.593Z"
        style={{
          fill: "#f77e2d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(240.17 177.253)"
      />
      <path
        d="M0 0h13.404L9.812-7.251H-3.593Z"
        style={{
          fill: "#f77e2d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(270.37 177.253)"
      />
      <path
        d="M0 0h13.397L9.805-7.251H-3.593Z"
        style={{
          fill: "#f77e2d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(300.57 177.253)"
      />
      <path
        d="M0 0v-.202l-3.499-7.049h-13.39L-13.296 0Z"
        style={{
          fill: "#f77e2d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(344.065 177.253)"
      />
      <path
        d="M225.837 147.39h-10.353v-40.828h10.353z"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M225.837 147.39h-2.352v-40.828h2.352z"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M326.588 147.39h-10.353v-40.828h10.353z"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M326.588 147.39h-3.061v-40.828h3.061zM225.837 147.422h-10.353v-5.142h10.353z"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M326.588 147.422h-10.353v-5.142h10.353z"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M225.837 177.282h-10.353v4.636h10.353z"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M225.837 177.282h-2.352v4.636h2.352z"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M326.588 177.282h-10.353v4.636h10.353z"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M326.588 177.282h-3.061v4.636h3.061zM225.837 177.278h-10.353v.584h10.353z"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M326.588 177.278h-10.353v.584h10.353z"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="m0 0-14.811-29.86h-11.363v4.119L-13.404 0Z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(223.376 177.253)"
      />
      <path
        d="m0 0-14.811-29.86h13.404L13.404 0Z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(240.17 177.253)"
      />
      <path
        d="m0 0-14.811-29.86h-13.404L-13.404 0Z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(283.774 177.253)"
      />
      <path
        d="m0 0-14.804-29.86h-13.404L-13.397 0Z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(313.967 177.253)"
      />
      <path
        d="m0 0-14.812-29.86h13.398L13.296-.202z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(330.77 177.253)"
      />
      <path
        d="m0 0-3.593-7.251h-13.411l3.6 7.251Z"
        style={{
          fill: "#00668e",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(223.376 177.253)"
      />
      <path
        d="m0 0-3.593-7.251h-13.404L-13.404 0Z"
        style={{
          fill: "#00668e",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(253.575 177.253)"
      />
      <path
        d="m0 0-3.593-7.251h-13.404L-13.404 0Z"
        style={{
          fill: "#00668e",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(283.774 177.253)"
      />
      <path
        d="m0 0-3.593-7.251H-16.99L-13.397 0Z"
        style={{
          fill: "#00668e",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(313.967 177.253)"
      />
      <path
        d="m0 0-3.499-7.049h-13.39l3.593 7.251z"
        style={{
          fill: "#00668e",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(344.065 177.05)"
      />
      <g clipPath="url(#f)" opacity={0.55}>
        <g clipPath="url(#g)">
          <g mask="url(#h)">
            <path
              d="M341.534 164.334H201.295v-14.936h140.239z"
              style={{
                fill: "url(#i)",
                stroke: "none",
              }}
            />
          </g>
        </g>
      </g>
      <path
        d="M0 0a9.23 9.23 0 0 1-9.231 9.23A9.23 9.23 0 0 1-18.461 0a9.23 9.23 0 0 1 9.23-9.231A9.23 9.23 0 0 1 0 0"
        style={{
          fill: "#ffbf4d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(229.621 188.398)"
      />
      <path
        d="M0 0a4.183 4.183 0 1 1-8.367-.001A4.183 4.183 0 0 1 0 0"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(224.574 188.398)"
      />
      <path
        d="M0 0a9.23 9.23 0 0 1-9.23 9.23A9.23 9.23 0 0 1-18.461 0 9.23 9.23 0 0 1-9.23-9.231 9.23 9.23 0 0 1 0 0"
        style={{
          fill: "#ffbf4d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(330.581 188.398)"
      />
      <path
        d="M0 0a4.183 4.183 0 1 1-8.367-.001A4.183 4.183 0 0 1 0 0"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(325.534 188.398)"
      />
      <path
        d="M0 0h-46.773l7.154 17.343v.011l2.878 6.997.793 1.919 1.292 3.122.263.635 2.878 6.998.529 1.273 5.099 12.381c.92 2.231 4.081 2.231 5.001 0l5.099-12.381.529-1.273 2.877-6.998.265-.635 1.292-3.122.792-1.919 2.878-6.997v-.011z"
        style={{
          fill: "#ffbf4d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(399.705 109.726)"
      />
      <g clipPath="url(#j)" opacity={0.29}>
        <path
          d="m0 0-3.064-7.436h46.776L40.647 0Z"
          style={{
            fill: "#f98d2b",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(355.998 117.162)"
        />
      </g>
      <path
        d="M402.766 114.172h-53.571v-7.573h53.571z"
        style={{
          fill: "#79c9e8",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M391.191 106.599h11.574v7.573h-11.574z"
        style={{
          fill: "#49b4d6",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <g clipPath="url(#k)" opacity={0.29}>
        <path
          d="M0 0h-6.616l-5.315 12.89v.009l-2.878 6.998-.792 1.918-1.292 3.122-.264.637-2.877 6.998-.529 1.273-4.287 10.423.803 1.958c.92 2.231 4.081 2.231 5.001 0l5.099-12.381.529-1.273 2.877-6.998.265-.637 1.292-3.122.792-1.918 2.878-6.998v-.009Z"
          style={{
            fill: "#f98d2b",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          transform="translate(397.865 114.175)"
        />
      </g>
      <path
        d="M0 0h-22.012l2.878 6.998h16.257z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(387.324 139.753)"
      />
      <path
        d="M0 0v-.011h-32.465V0l2.878 6.997h26.709z"
        style={{
          fill: "#0582c1",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(392.55 127.08)"
      />
      <path
        d="M0 0h-6.616l-2.877 6.998h6.616z"
        style={{
          fill: "#00668e",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(387.324 139.753)"
      />
      <path
        d="M0 0v-.011h-6.616V0l-2.878 6.997h6.616z"
        style={{
          fill: "#00668e",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(392.55 127.08)"
      />
      <path
        d="M0 0c3.495 0 6.533 2.538 7.005 6 .084.621.128 1.255.128 1.899 0 7.71-6.249 13.959-13.958 13.959-.317 0-.63-.014-.941-.035-.637 7.602-7.005 13.574-14.772 13.574-8.19 0-14.828-6.639-14.828-14.828 0-.363.017-.722.043-1.079a14.774 14.774 0 0 1-7.913 1.86c-7.449-.341-13.569-6.299-14.096-13.736l-.011-.177C-59.583 3.392-56.313 0-52.261 0Z"
        style={{
          fill: "#d7f0f9",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(101.558 279.792)"
      />
      <path
        d="M0 0c11.038 0 11.649 16.612.624 17.147a18.422 18.422 0 0 1-6.55-.86c-1.475 8.991-9.278 15.853-18.687 15.853-9.616 0-17.553-7.168-18.774-16.451a17.388 17.388 0 0 1-7.537 1.707c-1.508 0-2.971-.191-4.366-.55C-64.942 14.36-62.916 0-52.95 0Z"
        style={{
          fill: "#d7f0f9",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(434.692 268.828)"
      />
      <path
        d="M0 0c11.035 0 11.646 16.607.624 17.143a18.411 18.411 0 0 1-6.548-.861c-1.474 8.989-9.275 15.848-18.681 15.848-9.614 0-17.548-7.165-18.769-16.445a17.385 17.385 0 0 1-7.534 1.706c-1.508 0-2.97-.191-4.365-.551C-64.922 14.356-62.897 0-52.934 0Z"
        style={{
          fill: "#d7f0f9",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(302.135 350.677)"
      />
      <path
        d="M0 0c0 14.611-11.844 26.455-26.455 26.455-14.61 0-26.455-11.844-26.455-26.455 0-14.61 11.845-26.455 26.455-26.455C-11.844-26.455 0-14.61 0 0"
        style={{
          fill: "#ffbf4d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(152.9 346.437)"
      />
      <path
        d="M0 0c7.195-4.728 11.949-12.867 11.949-22.12 0-14.61-11.845-26.454-26.455-26.454-.073 0-.145.005-.218.005A26.323 26.323 0 0 1-.218-52.904c14.611 0 26.455 11.844 26.455 26.454C26.237-11.912 14.51-.118 0 0"
        style={{
          fill: "#ff9f50",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(126.664 372.887)"
      />
    </g>
    <g clipPath="url(#l)" transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
      <path
        d="M0 0a7.472 7.472 0 0 1-1.108-1.453l-.465.241a.604.604 0 1 1-.334-.68l.451-.234a7.26 7.26 0 0 1-.539-1.744l.746-.131A6.6 6.6 0 0 0 .543-.529z"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(260.103 32.284)"
      />
      <path
        d="M0 0c-.171 0-.341-.007-.51-.018l-.073.626a.719.719 0 0 1-.53 1.274.718.718 0 0 1-.223-1.36l.073-.624a7.564 7.564 0 0 1-2.059-.646l.327-.684A6.862 6.862 0 0 0 0-.759c.496 0 .993-.053 1.475-.157l.161.741A7.798 7.798 0 0 1 0 0"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(265.592 34.587)"
      />
      <path
        d="M0 0a.603.603 0 1 1-.677.59l-.416-.363a7.635 7.635 0 0 1-1.408 1.138l-.403-.64A6.755 6.755 0 0 0-.341-2.201l.691.312A7.353 7.353 0 0 1-.593-.344l.403.352A.573.573 0 0 1 0 0"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(272.194 32.04)"
      />
      <path
        d="M0 0a19.505 19.505 0 0 0-1.157-.034c-.88 0-1.733.06-2.478.179a8.65 8.65 0 0 0-1.27.292A6.17 6.17 0 0 1 .482-2.688c2.856 0 5.263 1.916 5.951 4.503C5.751 1.366 4.927.99 3.974.692 2.795.325 1.42.086 0 0"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(265.044 24.378)"
      />
      <path
        d="M0 0a1.361 1.361 0 0 0 0 2.721A1.361 1.361 0 0 0 0 0m-4.737-.908c-1.021 0-1.85.83-1.85 1.849a1.85 1.85 0 0 0 1.85 1.85A1.851 1.851 0 0 0-2.888.941c0-1.019-.83-1.849-1.849-1.849m2.159 6.514c-3.353 0-6.08-2.666-6.08-5.943 0-.426.047-.849.137-1.262.674-.482 2.707-.889 5.348-.73 2.879.173 5.297.957 6.672 2.158-.006.195-.02.391-.045.582C3.073 3.373.48 5.606-2.578 5.606"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(268.17 27.464)"
      />
      <path
        d="M0 0c-.294.116-.805.309-1.382.237-.551-.068-.844-.34-.844-1.007v-.865H.002v-1.718h-2.228v-5.454h-1.931v5.454h-1.34v1.718h1.34v.888c0 1.681.895 2.783 2.568 2.783.643 0 1.157-.122 1.59-.308z"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(281.595 31.203)"
      />
      <path
        d="M0 0c-.177.03-.357.047-.526.047-.896 0-1.51-.548-1.51-1.614v-3.908h-1.932v7.172h1.872V.912c.452.674 1.141.978 2.022.978.414 0 .847-.128 1.094-.337z"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(286.298 27.871)"
      />
      <path
        d="M0 0h3.175c-.177.918-.733 1.436-1.562 1.436C.703 1.436.171.889 0 0m5.114-.666c0-.222-.014-.488-.037-.651H-.007C.2-2.354.926-2.805 1.836-2.805c.621 0 1.295.251 1.812.688L4.781-3.36c-.814-.747-1.85-1.088-3.056-1.088-2.199 0-3.723 1.495-3.723 3.753 0 2.257 1.48 3.797 3.626 3.797 2.065 0 3.479-1.533 3.486-3.768"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(288.85 26.66)"
      />
      <path
        d="M0 0h3.175c-.177.918-.733 1.436-1.562 1.436C.703 1.436.17.889 0 0m5.114-.666c0-.222-.014-.488-.037-.651H-.007C.2-2.354.925-2.805 1.836-2.805c.621 0 1.295.251 1.813.688L4.781-3.36c-.814-.747-1.85-1.088-3.056-1.088-2.199 0-3.723 1.495-3.723 3.753 0 2.257 1.48 3.797 3.626 3.797 2.065 0 3.479-1.533 3.486-3.768"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(296.504 26.66)"
      />
      <path
        d="M0 0c0-1.132.747-1.976 1.843-1.976 1.139 0 1.85.881 1.85 1.976 0 1.096-.711 1.977-1.85 1.977C.747 1.977 0 1.133 0 0m5.677 0c0-2.153-1.495-3.774-3.471-3.774-.963 0-1.644.378-2.109.932V-6.55h-1.925V3.583H.06v-.792c.465.584 1.154.984 2.138.984 1.984 0 3.479-1.621 3.479-3.775"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(304.287 25.985)"
      />
      <path
        d="M312.809 22.396h-1.932v7.172h1.932zm.258 9.518c0-.695-.54-1.228-1.236-1.228-.695 0-1.228.533-1.228 1.228a1.22 1.22 0 0 0 1.228 1.243c.696 0 1.236-.547 1.236-1.243"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="m0 0 2.701 2.554H5.07L1.591-.821l3.501-3.797H2.716L0-1.717v-2.901h-1.932V6.039H0Z"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(316.032 27.015)"
      />
      <path
        d="M0 0v3.208c-.396.615-1.245 1.143-2.124 1.143-1.421 0-2.271-1.172-2.271-2.74 0-1.567.85-2.724 2.271-2.724C-1.245-1.113-.396-.615 0 0m0-1.919v1.055c-.527-.718-1.392-1.231-2.388-1.231-1.845 0-3.149 1.407-3.149 3.706 0 2.256 1.289 3.721 3.149 3.721.952 0 1.817-.469 2.388-1.245v3.765h1.099v-9.771z"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(184.413 24.38)"
      />
      <path
        d="M0 0c-1.509 0-2.256-1.274-2.314-2.358h4.643C2.314-1.304 1.611 0 0 0m-3.471-2.798C-3.471-.747-2.007.908.015.908c2.138 0 3.398-1.67 3.398-3.794v-.278h-5.727C-2.227-4.497-1.289-5.61.234-5.61c.806 0 1.626.322 2.183.893l.527-.718C2.241-6.138 1.289-6.519.132-6.519c-2.095 0-3.603 1.509-3.603 3.721"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(190.403 28.804)"
      />
      <path
        d="m0 0 .571.791C1.04.249 1.934-.22 2.9-.22c1.084 0 1.67.513 1.67 1.216 0 1.714-4.38.659-4.38 3.281 0 1.099.953 2.036 2.637 2.036 1.201 0 2.051-.454 2.622-1.025l-.513-.762c-.424.513-1.201.894-2.109.894-.967 0-1.567-.483-1.567-1.113 0-1.553 4.379-.528 4.379-3.282 0-1.186-.952-2.138-2.783-2.138C1.699-1.113.718-.762 0 0"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(194.607 23.398)"
      />
      <path
        d="M201.667 29.536h1.099v-7.075h-1.099zm-.19 1.743c0 .41.337.747.732.747a.75.75 0 0 0 .747-.747.737.737 0 0 0-.747-.732.734.734 0 0 0-.732.732"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
      />
      <path
        d="M0 0v3.091c-.381.615-1.23 1.142-2.109 1.142-1.421 0-2.27-1.128-2.27-2.695 0-1.553.849-2.695 2.27-2.695C-1.23-1.157-.381-.615 0 0m-5.126-3.853.556.821c.586-.703 1.289-.967 2.3-.967 1.172 0 2.27.571 2.27 2.124v1.011c-.512-.718-1.376-1.26-2.373-1.26-1.845 0-3.149 1.377-3.149 3.662 0 2.271 1.289 3.677 3.149 3.677.952 0 1.802-.469 2.373-1.245v1.069h1.099v-6.87c0-2.344-1.641-3.076-3.369-3.076-1.201 0-2.022.234-2.856 1.054"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(209.708 24.497)"
      />
      <path
        d="M0 0v4.629C0 5.874-.629 6.27-1.582 6.27c-.864 0-1.685-.528-2.094-1.099V0h-1.099v7.075h1.099V6.05c.498.586 1.464 1.201 2.519 1.201 1.479 0 2.256-.747 2.256-2.285V0Z"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(217.413 22.46)"
      />
      <path
        d="M0 0c-1.509 0-2.256-1.274-2.314-2.358h4.643C2.314-1.304 1.611 0 0 0m-3.472-2.798C-3.472-.747-2.007.908.015.908c2.138 0 3.398-1.67 3.398-3.794v-.278h-5.727C-2.227-4.497-1.289-5.61.234-5.61c.806 0 1.626.322 2.183.893l.527-.718C2.241-6.138 1.289-6.519.132-6.519c-2.095 0-3.604 1.509-3.604 3.721"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(223.404 28.804)"
      />
      <path
        d="M0 0v3.208c-.396.615-1.245 1.143-2.124 1.143-1.421 0-2.271-1.172-2.271-2.74 0-1.567.85-2.724 2.271-2.724C-1.245-1.113-.396-.615 0 0m0-1.919v1.055c-.527-.718-1.392-1.231-2.388-1.231-1.845 0-3.149 1.407-3.149 3.706 0 2.256 1.289 3.721 3.149 3.721.952 0 1.817-.469 2.388-1.245v3.765h1.099v-9.771z"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(233.35 24.38)"
      />
      <path
        d="M0 0c1.436 0 2.285 1.157 2.285 2.725 0 1.567-.849 2.739-2.285 2.739-.864 0-1.729-.527-2.109-1.143V1.113C-1.729.498-.864 0 0 0m-3.208-.806v9.771h1.099V5.2c.571.777 1.421 1.245 2.387 1.245 1.846 0 3.15-1.465 3.15-3.72 0-2.3-1.304-3.706-3.15-3.706-.996 0-1.86.512-2.387 1.23V-.806z"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(242.9 23.267)"
      />
      <path
        d="M0 0a1.69 1.69 0 0 1 .601-.117c.483 0 .805.161 1.055.747l.469 1.069-2.96 7.149H.352L2.71 3.047l2.344 5.801h1.201L2.71.337C2.285-.688 1.567-1.084.63-1.099c-.234 0-.601.044-.805.103z"
        style={{
          fill: "#1d262d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(247.528 20.689)"
      />
    </g>
  </svg>
)

export default Error
